const typography = {
    default: {
      fontFamily: '"Nunito Sans", Arial, sans-serif',
    },
    alternative: {
      fontFamily: '"Roboto", Arial, sans-serif',
    },
  };
  
  export default typography;
  